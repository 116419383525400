export const connectors = [
	{
		ID: 1,
		Name: 'Azure test conn',
		ConnectionPoint: 'BTEng-CPB',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 2
	},
	{
		ID: 2,
		Name: 'edsvADV',
		ConnectionPoint: '',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 5
	},
	{
		ID: 3,
		Name: 'test443',
		ConnectionPoint: 'WIN-7JIFCV7KIOH',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 2
	},
	{
		ID: 4,
		Name: 'testcorp3006',
		ConnectionPoint: 'WIN-7JIFCV7KIOH',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 5
	},
	{
		ID: 5,
		Name: 'test-1',
		ConnectionPoint: 'https://dev-96965829.okta.com',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 1
	},
	{
		ID: 6,
		Name: 'test connector',
		ConnectionPoint: '',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 5
	},
	{
		ID: 7,
		Name: 'Test Azure',
		ConnectionPoint: 'BTEng-CPB',
		Connectivity: 'Connector off',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'None',
		Count: 7
	},
	{
		ID: 8,
		Name: 'Test-PS-Ro',
		ConnectionPoint: 'https://devdemo.ps-dev.beyondtrustcloud.com',
		Connectivity: 'Connector off',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'None',
		Count: 9
	},
	{
		ID: 9,
		Name: 'Test Connector R',
		ConnectionPoint: 'https://dev-29642517.okta.com',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 1
	},
	{
		ID: 10,
		Name: 'Azure test conn',
		ConnectionPoint: 'BTEng-CPB',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 3
	},
	{
		ID: 12,
		Name: 'edsvADV',
		ConnectionPoint: '',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 3
	},
	{
		ID: 13,
		Name: 'test443',
		ConnectionPoint: 'WIN-7JIFCV7KIOH',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 10
	},
	{
		ID: 14,
		Name: 'testcorp3006',
		ConnectionPoint: 'WIN-7JIFCV7KIOH',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 6
	},
	{
		ID: 15,
		Name: 'test-1',
		ConnectionPoint: 'https://dev-96965829.okta.com',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 8
	},
	{
		ID: 16,
		Name: 'test connector',
		ConnectionPoint: '',
		Connectivity: 'Failed',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'Critical',
		Count: 1
	},
	{
		ID: 17,
		Name: 'Test Azure',
		ConnectionPoint: 'BTEng-CPB',
		Connectivity: 'Connector Off',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'None',
		Count: 6
	},
	{
		ID: 18,
		Name: 'Test-PS-Ro',
		ConnectionPoint: 'https://devdemo.ps-dev.beyondtrustcloud.com',
		Connectivity: 'Connector Off',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'None',
		Count: 1
	},
	{
		ID: 19,
		Name: 'Test Connector R',
		ConnectionPoint: 'https://dev-29642517.okta.com',
		Connectivity: 'Warning',
		LastActivity: new Date(2024, 3, 18, 7, 21, 0),
		Severity: 'High',
		Count: 4
	}
];
