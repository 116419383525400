import { Component, TemplateRef, ViewChild, OnDestroy} from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import {
	NotificationRef,
	NotificationService,
} from '@progress/kendo-angular-notification';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';

// Data Sources
import {
	toastStylesDefaultThemeAll,
	toastStylesDefaultThemeInfo,
	toastStylesDefaultThemeSuccess,
	toastStylesDefaultThemeWarning,
	toastStylesDefaultThemeError,
	toastStylesDarkThemeAll,
	toastStylesDarkThemeInfo,
	toastStylesDarkThemeSuccess,
	toastStylesDarkThemeWarning,
	toastStylesDarkThemeError,
	toastStylesTypography,
	toastStylesStructure,
	toastsTextLinkStylesStructure,
	toastTextLinkStylesTypography
} from './toast-styles';

import {
	textLinkStylesDefaultTheme,
	textLinkStylesDarkTheme,
} from '../text-link/text-link-styles';

import {
	buttonStylesDefaultThemeSecondary,
	buttonStylesDarkThemeSecondary,
	buttonStylesTypography,
	buttonStylesStructure,
} from '../button/button-styles';
import { ToastTemplateComponent } from './toast-template.component';
import { ChangelogService } from 'src/app/services/changelog.service';
import {
	shCheck,
	shCirclePlay,
	shCircleStop,
	shCircleXmark,
	shLink,
	shXmark,
} from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.less'],
})
export class ToastComponent implements OnDestroy {
	@ViewChild('toastControlsTemplate', { read: TemplateRef })
	public notificationTemplate: TemplateRef<unknown>;

	public currLive = 'success';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;
	public doThisIcon = shCheck;
	public notThisIcon = shXmark;
	public pauseIcon = shCircleStop;
	public resumeIcon = shCirclePlay;
	public closeIcon = shCircleXmark;

	public toastRefs: NotificationRef[] = [];
	public controlsRef?: NotificationRef;

	// Imported Style Variables
	public stylesDefaultAll = toastStylesDefaultThemeAll;
	public stylesDefaultInfo = toastStylesDefaultThemeInfo;
	public stylesDefaultSuccess = toastStylesDefaultThemeSuccess;
	public stylesDefaultWarning = toastStylesDefaultThemeWarning;
	public stylesDefaultError = toastStylesDefaultThemeError;
	public stylesDarkAll = toastStylesDarkThemeAll;
	public stylesDarkInfo = toastStylesDarkThemeInfo;
	public stylesDarkSuccess = toastStylesDarkThemeSuccess;
	public stylesDarkWarning = toastStylesDarkThemeWarning;
	public stylesDarkError = toastStylesDarkThemeError;
	public stylesTypography = toastStylesTypography;
	public stylesStructure = toastStylesStructure;
	public textLinkStylesDefault = textLinkStylesDefaultTheme;
	public textLinkStylesDark = textLinkStylesDarkTheme;
	public textLinkStylesTypography = toastTextLinkStylesTypography;
	public textLinkStylesStructure = toastsTextLinkStylesStructure;
	public buttonStylesDefaultSecondary = buttonStylesDefaultThemeSecondary;
	public buttonStylesDarkSecondary = buttonStylesDarkThemeSecondary;
	public buttonStylesTypography = buttonStylesTypography;
	public buttonStylesStructure = buttonStylesStructure;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Duration',
					value: 'duration',
				},
				{
					name: 'Pause/resume',
					value: 'pause-resume',
				},
				{
					name: 'Multiple toasts',
					value: 'multiple',
				},
				{
					name: 'Actions and dismiss',
					value: 'actions',
				},
				{
					name: 'Dismiss all',
					value: 'dismiss-all',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Animation',
					value: 'animation',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Informational',
					value: 'info',
				},
				{
					name: 'Success',
					value: 'success',
				},

				{
					name: 'Warning',
					value: 'warning',
				},
				{
					name: 'Error',
					value: 'error',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Positioning',
					value: 'positioning',
				},
				{
					name: 'Icons',
					value: 'icons',
				},
				{
					name: 'Toasts versus banners',
					value: 'toasts-vs-banners',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		scrollService: WindowScrollService,
		private notificationService: NotificationService,
		public changelogService: ChangelogService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	private showControls(): void {
		this.controlsRef = this.notificationService.show({
			content: this.notificationTemplate,
			closable: true,
			cssClass: 'sh-hide-x',
			type: { style: 'none', icon: false },
			width: 384,
		});
	}

	public ngOnDestroy(): void {
		document.documentElement.setAttribute('data-theme', 'light');
		this.dismissAll();
	}

	private initToast(
		ref: NotificationRef,
		type: 'success' | 'info' | 'warning' | 'error'
	) {
		this.toastRefs.push(ref);
		ref.content!.instance.toastType = type;
		if (type === 'success') {
		 	ref.content!.instance.content = 'You saved the changes.';
		} else if (type === 'info') {
			ref.content!.instance.content = 'You didn’t make any changes, so there’s nothing to save.';
		} else if (type === 'warning') {
			ref.content!.instance.content = 'There will be system maintenance at 2:30 EST. You won’t be able to make any changes during this time.';
		} else if (type === 'error') {
		  	ref.content!.instance.content = 'Changes not saved. That file name already exists.';
		}
		ref.content!.instance.start(8000);
		ref.content!.instance.onComplete.subscribe(() => {
			ref.hide();
			this.toastRefs.splice(this.toastRefs.indexOf(ref), 1);
			if (this.toastRefs.length === 0) {
				this.controlsRef?.hide();
			}
		});
	}

	public showToast() {
		if (this.toastRefs.length === 0) {
			this.showControls();
		}
		const type = this.currLive as any;
		const ref = this.notificationService.show({
			content: ToastTemplateComponent,
			closable: true,
			animation: { type: 'slide', duration: 250 },
			cssClass: 'sh-hide-x',
			type: { style: type, icon: false },
			width: 384,
		});
		this.initToast(ref, type);
	}

	public pauseAll() {
		for (const toast of this.toastRefs) {
			toast.content.instance.pause();
		}
	}

	public resumeAll() {
		for (const toast of this.toastRefs) {
			toast.content.instance.resume();
		}
	}

	public dismissAll() {
		for (const toast of this.toastRefs) {
			toast.hide();
		}
		this.toastRefs = [];
		this.controlsRef?.hide();
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public toastHTML: any = `
<ng-template #toastControlsTemplate>
	<div class="sh-toast-controls">
		<kendo-button [svgIcon]="pauseIcon" themeColor="primary" fillMode="flat" (click)="pauseAll()"> Pause </kendo-button>
		<kendo-button [svgIcon]="resumeIcon" themeColor="primary" fillMode="flat" (click)="resumeAll()"> Resume </kendo-button>
		<kendo-button [svgIcon]="closeIcon" themeColor="primary" fillMode="flat" (click)="dismissAll()"> Dismiss all </kendo-button>
	</div>
</ng-template>
<div class="toast-type-buttons">
    <kendo-button (click)="showToast()" themeColor="primary"> Show toast </kendo-button>
</div>`;

	public toastTs = `
import { Component, TemplateRef, ViewChild } from '@angular/core';
import { NotificationRef, NotificationService } from '@progress/kendo-angular-notification';
import { ToastTemplateComponent } from './toast-template.component';
import { shCirclePlay, shCircleStop, shCircleXmark } from '@beyondtrust/shield-icons';

@Component({
	selector: 'app-toast',
	templateUrl: './toast.component.html',
	styleUrls: ['./toast.component.scss']
})
export class ToastComponent {
	@ViewChild('toastControlsTemplate', { read: TemplateRef }) public notificationTemplate: TemplateRef<unknown>;

	public pauseIcon = shCircleStop;
	public resumeIcon = shCirclePlay;
	public closeIcon = shCircleXmark;

	private toastRefs: NotificationRef[] = [];
	private controlsRef?: NotificationRef;

	constructor(private notificationService: NotificationService) {}

	private showControls(): void {
		this.controlsRef = this.notificationService.show({
			content: this.notificationTemplate,
			closable: true,
			cssClass: 'sh-hide-x',
		  	type: { style: 'none', icon: false },
			width: 384
		});
	}

	private initToast(ref: NotificationRef, type: 'success' | 'info' | 'warning' | 'error') {
		this.toastRefs.push(ref);
		ref.content!.instance.toastType = type;
		ref.content!.instance.title = type[0].toUpperCase() + type.slice(1);
		if (type === 'success') {
			ref.content!.instance.content = 'You saved the changes.';
		} else if (type === 'info') {
			ref.content!.instance.content = 'You didn’t make any changes, so there’s nothing to save.';
		} else if (type === 'warning') {
			ref.content!.instance.content = 'There will be system maintenance at 2:30 EST. You won’t be able to make any changes during this time.';
		} else if (type === 'error') {
			ref.content!.instance.content = 'Changes not saved. That file name already exists.';
		}
		ref.content!.instance.start(8000);
		ref.content!.instance.onComplete.subscribe(() => {
		  	ref.hide();
		  	this.toastRefs.splice(this.toastRefs.indexOf(ref), 1);
		  	if (this.toastRefs.length === 0) {
				this.controlsRef?.hide();
		  	}
		});
	}

	public showToast(type: 'success' | 'info' | 'warning' | 'error') {
		this.controlsRef?.hide();
		const ref = this.notificationService.show({
			content: ToastTemplateComponent,
			closable: true,
			cssClass: 'sh-hide-x',
			animation: { type: 'slide', duration: 250 },
			type: { style: type, icon: false },
			width: 384
		});
		this.initToast(ref, type);
		this.showControls();
	}

	public pauseAll() {
		for (const toast of this.toastRefs) {
		  	toast.content.instance.pause();
		}
	}

	public resumeAll() {
		for (const toast of this.toastRefs) {
		  	toast.content.instance.resume();
		}
	}

	public dismissAll() {
		for (const toast of this.toastRefs) {
			toast.hide();
		}
		this.toastRefs = [];
		this.controlsRef?.hide();
	}
}`;

	public toastTemplateTs = `
import { Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { shCircleCheck, shCircleInfo, shCircleSlash } from '@beyondtrust/shield-icons';

const warningDuotoneIcon = {
	name: 'shCircleWarningDuotone',
	content: '<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0423 3.76202C7.15373 3.76202 3.19037 7.72538 3.19037 12.614C3.19037 17.5026 7.15373 21.466 12.0423 21.466C16.931 21.466 20.8943 17.503 20.8943 12.614C20.8943 7.72496 16.931 3.76202 12.0423 3.76202ZM12.0423 18.1466C11.4304 18.1466 10.9357 17.6511 10.9357 17.04C10.9357 16.4289 11.4304 15.9334 12.0423 15.9334C12.6543 15.9334 13.1489 16.4285 13.1489 17.04C13.1489 17.6515 12.6543 18.1466 12.0423 18.1466ZM13.1489 12.614C13.1489 13.2255 12.6543 13.7206 12.0423 13.7206C11.4304 13.7206 10.9357 13.2251 10.9357 12.614V8.18801C10.9357 7.57736 11.4304 7.08141 12.0423 7.08141C12.6543 7.08141 13.1489 7.57736 13.1489 8.18801V12.614Z" fill="#FF9C00"/><g clip-path="url(#clip0_10636_275)"><path d="M22.3951 14.8185C23.4618 9.11843 19.7058 3.63288 14.0058 2.56615C8.30573 1.49942 2.82018 5.25545 1.75345 10.9555C0.686718 16.6555 4.44275 22.1411 10.1428 23.2078C15.8428 24.2745 21.3284 20.5185 22.3951 14.8185Z" fill="#FF9C00"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.0779 19.4492C11.3541 19.4492 10.7609 18.856 10.7609 18.1323C10.7609 17.4085 11.3423 16.8153 12.0779 16.8153C12.8135 16.8153 13.3948 17.4085 13.3948 18.1323C13.3948 18.856 12.8135 19.4492 12.0779 19.4492Z" fill="#253746"/><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3948 12.8882C13.3948 13.6119 12.8135 14.2051 12.0779 14.2051C11.3423 14.2051 10.7609 13.6119 10.7609 12.8882V7.63226C10.7609 6.90853 11.3423 6.31531 12.0779 6.31531C12.8135 6.31531 13.3948 6.90853 13.3948 7.63226V12.8882Z" fill="#253746"/></g><defs><clipPath id="clip0_10636_275"><rect width="21" height="21" fill="white" transform="translate(1.57788 2.38818)"/></clipPath></defs>',
	viewBox: '0 0 25 25'
};

@Component({
	selector: 'sh-custom-toast',
	template: \`
		<kendo-progressbar [value]="time" [animation]="true" [max]="total" [label]="false"></kendo-progressbar>
		<kendo-svg-icon [icon]="icon"></kendo-svg-icon>
		<p class="sh-toast-content">{{ content }}</p>
		<button kendoButton themeColor="primary" fillMode="flat" (click)="done()">Dismiss</button>
	\`,
	styles: []
})
export class ToastTemplateComponent implements OnDestroy {
	private intervalId: any = null;
	public time: number = 0;
	public total: number = 0;

	public get icon() {
		if (this.toastType === 'success') {
			return shCircleCheck;
		} else if (this.toastType === 'info') {
			return shCircleInfo;
		} else if (this.toastType === 'warning') {
			return warningDuotoneIcon;
		} else {
			return shCircleSlash;
		}
	}

	@Input() public toastType: string;
	@Input() public content: string = '';

	@Output() public onComplete: EventEmitter<void> = new EventEmitter();

	public ngOnDestroy(): void {
		clearInterval(this.intervalId);
	}

	public start(ms: number): void {
		this.total = ms;
		this.time = ms;
		this.startCountdown();
	}

	private startCountdown(): void {
		this.intervalId = setInterval(() => {
			this.time -= 10;
			if (this.time < 0) {
				this.done();
			}
		}, 10);
	}

	public pause(): void {
		if (this.intervalId) {
			clearInterval(this.intervalId);
			this.intervalId = null;
		}
	}

	public resume(): void {
		if (!this.intervalId) {
			this.startCountdown();
		}
	}

	public done(): void {
		this.onComplete.emit();
		clearInterval(this.intervalId);
	}
}`;
}
