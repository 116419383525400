<div id="color-tile-container" class="color-tile-container" (clickOutside)="onClickedOutside($event)"
	[exclude]="'color-tile-input-container, color-tile-icon, color-tile-input, color-tile-input-dropdown-container, color-tile-input-dropdown-list, color-tile-input-dropdown-item'">
	<span class="color-tile light" [class.light]="colorData.type === 'light'" [class.dark]="colorData.type === 'dark'"
		[ngStyle]="{'background-color': colorData.hex}" (click)="onTileClicked()">{{colorData.name}}
		<svg class="color-tile-arrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
			<path
				d="M310.6 246.6l-127.1 128C176.4 380.9 168.2 384 160 384s-16.38-3.125-22.63-9.375l-127.1-128C.2244 237.5-2.516 223.7 2.438 211.8S19.07 192 32 192h255.1c12.94 0 24.62 7.781 29.58 19.75S319.8 237.5 310.6 246.6z" />
		</svg></span>
	<ul class="color-tile-list" *ngIf="colorDropDownShown">
		<li class="color-tile-list-item" (click)="copyToClipboard(colorData.name)">
			<span class="color-tile-list-item-label">Copy Name: </span>{{colorData.name}}
		</li>
		<li class="color-tile-list-item" (click)="copyToClipboard(colorData.hex)">
			<span class="color-tile-list-item-label">Copy Hex: </span>{{colorData.hex}}
		</li>
		<li class="color-tile-list-item" (click)="copyToClipboard(colorData.rgb)">
			<span class="color-tile-list-item-label">Copy RGB: </span>{{colorData.rgb}}
		</li>
		<li class="color-tile-list-item" (click)="copyToClipboard(colorData.hsl)">
			<span class="color-tile-list-item-label">Copy HSL: </span>{{colorData.hsl}}
		</li>
		<li class="color-tile-list-item" (click)="copyToClipboard(colorData.token)">
			<span class="color-tile-list-item-label">Copy Token: </span>{{colorData.token}}
		</li>
	</ul>
</div>