import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { SVGIcon } from '@progress/kendo-svg-icons';
import * as ShieldIcons from '@beyondtrust/shield-icons';

// Data Sources
import {
	buttonGroupStylesDefaultTheme,
	buttonGroupStylesDarkTheme,
	buttonGroupStylesTypography,
	buttonGroupStylesStructure,
	buttonGroupStylesStructureIcon,
	buttonGroupStylesDefaultThemeIcon,
	buttonGroupStylesDarkThemeIcon,
} from './button-group-styles';

export interface IButton {
	text: string;
	svgIcon?: SVGIcon;
	selected?: boolean;
	disabled?: boolean;
}

@Component({
	selector: 'app-button-group',
	templateUrl: './button-group.component.html',
	styleUrls: ['./button-group.component.less'],
})
export class ButtonGroupComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = buttonGroupStylesDefaultTheme;
	public stylesDefaultIcon = buttonGroupStylesDefaultThemeIcon;
	public stylesDark = buttonGroupStylesDarkTheme;
	public stylesDarkIcon = buttonGroupStylesDarkThemeIcon;
	public stylesTypography = buttonGroupStylesTypography;
	public stylesStructure = buttonGroupStylesStructure;
	public stylesStructureIcon = buttonGroupStylesStructureIcon;
	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Code examples',
					value: 'code',
					empty: true,
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Select',
					value: 'selected',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
				{
					name: 'Disable',
					value: 'disabled',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Default',
					value: 'default',
				},
				{
					name: 'Button with icon',
					value: 'button-with-icon',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Wrapping',
					value: 'wrapping',
				},
				{
					name: 'Button groups versus tabs',
					value: 'general',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public wrapSelection: string = '5';
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public icons = ShieldIcons;
	public clock = this.icons.shClock;
	public circleCheck = this.icons.shCircleCheck;
	public circleXmark = this.icons.shCircleXmark;
	public minus = this.icons.shCircleMinus;
	public circleQuestion = this.icons.shCircleQuestion;

	public buttons: IButton[] = [
		{ text: 'Away', disabled: false, selected: true },
		{
			text: 'Available',
			disabled: false,
		},
		{ text: 'Offline', disabled: false },
		{ text: 'Do Not Disturb', disabled: true },
		{ text: 'Unknown', disabled: false },
	];

	public buttonsWithIcons: IButton[] = [
		{ text: 'Away', svgIcon: this.clock, disabled: false, selected: true },
		{
			text: 'Available',
			svgIcon: this.circleCheck,
			disabled: false,
		},
		{ text: 'Offline', svgIcon: this.circleXmark, disabled: false },
		{ text: 'Do Not Disturb', svgIcon: this.minus, disabled: true },
		{ text: 'Unknown', svgIcon: this.circleQuestion, disabled: false },
	];
}
