import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import { shLink } from '@beyondtrust/shield-icons';

// Data Sources
import {
	paginationStylesDefaultTheme,
	paginationStylesDarkTheme,
	paginationStylesTypography,
	paginationStylesStructure,
	paginationTextFieldStylesStructure,
	paginationDropDownStylesStructure,
	paginationTextFieldStylesDefaultTheme,
	paginationTextFieldStylesDarkTheme,
	paginationTextFieldStylesTypography,
} from './pagination-styles';
import {
	dropDownStylesDarkTheme,
	dropDownStylesDefaultTheme,
	dropDownStylesTypography
} from '../drop-down/drop-down-styles';
import {
	buttonStylesDarkThemeIcon,
	buttonStylesDefaultThemeIcon,
	buttonStylesStructureIcon
} from '../button/button-styles';
import { PageChangeEvent } from '@progress/kendo-angular-pager';
import { data } from "./data";

@Component({
	selector: 'app-pagination',
	templateUrl: './pagination.component.html',
	styleUrls: ['./pagination.component.less'],
})
export class PaginationComponent implements OnDestroy {
	public currLive = 'default';
	public currTheme = 'default';
	public currVisible = 'overview';
	public linkIcon = shLink;

	// Imported Style Variables
	public stylesDefault = paginationStylesDefaultTheme;
	public textFieldStylesDefault = paginationTextFieldStylesDefaultTheme
	public dropDownStylesDefault =  JSON.parse(JSON.stringify(dropDownStylesDefaultTheme)).filter(style => style.name.toLowerCase() != 'label text');
	public iconButtonStylesDefault = buttonStylesDefaultThemeIcon;
	public stylesDark = paginationStylesDarkTheme;
	public textFieldStylesDark = paginationTextFieldStylesDarkTheme;
	public dropDownStylesDark = JSON.parse(JSON.stringify(dropDownStylesDarkTheme)).filter(style => style.name.toLowerCase() != 'label text');
	public iconButtonStylesDark = buttonStylesDarkThemeIcon;
	public stylesTypography = paginationStylesTypography;
	public textFieldStylesTypography = paginationTextFieldStylesTypography;
	public dropDownStylesTypography = dropDownStylesTypography;
	public stylesStructure = paginationStylesStructure;
	public textFieldStylesStructure = paginationTextFieldStylesStructure;
	public dropDownStylesStructure = paginationDropDownStylesStructure;
	public iconButtonStylesStructure = buttonStylesStructureIcon;
	public skip = 0;
	public pageSize = 10;
	public pagedData = [];

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Disable',
					value: 'disable',
				},
				{
					name: 'Pagination controls',
					value: 'controls',
				},
				{
					name: 'Items per page drop-down',
					value: 'items-per-page',
				},
				{
					name: 'Showing items text',
					value: 'items-text',
				},
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general',
				},
				{
					name: 'Content',
					value: 'content',
				},
				{
					name: 'Position',
					value: 'position',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public ngOnInit(): void {
		this.pageData();
	}

	public onPageChange(e: PageChangeEvent): void {
		this.skip = e.skip;
		this.pageSize = e.take;
		this.pageData();
	}

	private pageData(): void {
		this.pagedData = data.slice(this.skip, this.skip + this.pageSize);
	}

	public paginationHTML = `<kendo-datapager
	aria-controls="data-id"
	size="medium"
	type="input"
	[total]="100"
	[pageSize]="pageSize"
	[skip]="skip"
	(pageChange)="onPageChange($event)"
	[pageSizeValues]="[10, 25, 50, 100, 200]">
</kendo-datapager>`;
}
