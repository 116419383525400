import { AfterViewInit, Component, ViewChild, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';
import * as ShieldIcons from '@beyondtrust/shield-icons';

// Data Sources
import {
	passwordFieldStylesDefaultTheme,
	passwordFieldStylesDefaultErrorTheme,
	passwordFieldStylesDefaultWarningTheme,
	passwordFieldStylesDefaultSuccessTheme,
	passwordFieldStylesDarkTheme,
	passwordFieldStylesDarkErrorTheme,
	passwordFieldStylesDarkWarningTheme,
	passwordFieldStylesDarkSuccessTheme,
	passwordFieldStylesTypography,
	passwordFieldStylesStructure,
} from './password-field-styles';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { textFieldStylesDarkTheme, textFieldStylesDefaultTheme } from '../text-field/text-field-styles';

@Component({
	selector: 'app-password-field',
	templateUrl: './password-field.component.html',
	styleUrls: ['./password-field.component.less'],
})
export class PasswordFieldComponent implements AfterViewInit, OnDestroy {
	public currLive = 'login';
	public currTheme = 'default';
	public currVisible = 'overview';

	// Imported Style Variables
	public stylesDefault = passwordFieldStylesDefaultTheme;
	public stylesDefaultError =passwordFieldStylesDefaultErrorTheme;
	public stylesDefaultWarn = passwordFieldStylesDefaultWarningTheme;
	public stylesDefaultSuccess = passwordFieldStylesDefaultSuccessTheme;
	public stylesDark = passwordFieldStylesDarkTheme;
	public stylesDarkError = passwordFieldStylesDarkErrorTheme;
	public stylesDarkWarn = passwordFieldStylesDarkWarningTheme;
	public stylesDarkSuccess = passwordFieldStylesDarkSuccessTheme;
	public stylesTypography = passwordFieldStylesTypography;
	public stylesStructure = passwordFieldStylesStructure;
	
	public xMarkIcon = ShieldIcons.shXmark;
	public eyeIcon = ShieldIcons.shEye;
	public eyeIconHide = ShieldIcons.shEyeSlash;
	public copyIcon = ShieldIcons.shCopy;
	public toggleIcon = this.eyeIcon;
	public iconView = 'Show';
	public inputValue = 0;
	public labelText = "";
	public strengthValue = 0;
	public errorMessage: string;
	
	// @ViewChild("password") public password: TextBoxComponent;
	@ViewChild("loginPassword") public loginPassword: TextBoxComponent;

	public form: FormGroup = new FormGroup({
		loginPassword: new FormControl(),
		// password creation field
		// password: new FormControl('', [Validators.required, this.CustomValidation]),
	});

	public pageSubNavData = [ 
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: [],
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: [],
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started',
				},
				{
					name: 'Code examples',
					value: 'code',
				},
				{
					name: 'API',
					value: 'api',
				},
			],
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: [],
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Disable',
					value: 'disable',
				},
				{
					name: 'Show/Hide',
					value: 'show',
				},
				{
					name: 'Focus',
					value: 'focus',
				},
			],
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Password entry',
					value: 'login',
				},
				// {
				// 	name: 'New password field',
				// 	value: 'new',
				// },
			],
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'Content',
					value: 'content',
				},
			],
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: [],
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: [],
		},
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private router: Router,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}
	
	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url',
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public ngAfterViewInit(): void {
		// password creation field
		// this.password.input.nativeElement.type = "password";
		this.loginPassword.input.nativeElement.type = "password";
	}

	public toggleLoginVisibility(): void {
		const inputEl = this.loginPassword.input.nativeElement;
	
		if (inputEl.type === 'password') {
			this.toggleIcon = this.eyeIconHide;
			this.iconView = 'Hide';
			inputEl.type = 'text'; 
		} else {
			this.toggleIcon = this.eyeIcon;
			inputEl.type = 'password';
			this.iconView = 'Show';
		}
	}

	// Password Creation Field
	// public progressClass: string = "";
	
	// public emptyStyles: { [key: string]: string } = {
	// 	background: "transparent",
	// };

	// public onInput(e: Event): void {
	// 	let inputValue = (e.target as HTMLInputElement).value;
	// 	if (inputValue.length === 0) {
	// 		this.strengthValue = 0;
	// 	} else {
	// 		let strengthAssessors = [this.minLength.bind(this), this.oneLowerCase, this.oneUpperCase, this.oneNumber, this.oneSpecialCharacter]
			
	// 		let strengthValue = 0;
	// 		strengthAssessors.forEach((func) => {
	// 			if (func(inputValue)) {
	// 			strengthValue++;
	// 			}
	// 		})
			
	// 		let colorDefinitions = {
	// 			// value of this object is an array with [strength, className for color, strength value] 
	// 			1: ["Very weak", "sh-progress-error", 25],
	// 			2: ["Very weak", "sh-progress-error", 25],
	// 			3: ["Weak", "sh-progress-warn", 50],
	// 			4: ["Good", "sh-progress-warn", 75],
	// 			5: ["Strong", "sh-progress-success", 100]
	// 		}
			
	// 		if (strengthValue > 0) {
	// 			let definition = colorDefinitions[strengthValue]
	// 			this.strengthValue = definition[2];
	// 			this.updateAppearance(definition[0], definition[1]);
	// 		}
	// 	}	
	// }

	// public toggleVisibility(): void {
	// 	const inputEl = this.password.input.nativeElement;
	// 	if (inputEl.type === 'password') {
	// 		this.toggleIcon = this.eyeIconHide;
	// 		this.iconView = 'Hide';
	// 		inputEl.type = 'text'; 
	// 	} else {
	// 		this.toggleIcon = this.eyeIcon;
	// 		inputEl.type = 'password';
	// 		this.iconView = 'Show';
	// 	}
	// }

	// private updateAppearance(text: string, className: string): void {
	// 	this.labelText = text;
	// 	this.progressClass = className;
	// }

	// private minLength(password: string): boolean {
	// 	return password.length >= 12;
	// }

	// private oneLowerCase(password: string): boolean {
	// 	return /[a-z]/.test(password)
	// }

	// private oneUpperCase(password: string): boolean {
	// 	return /[A-Z]/.test(password)
	// }

	// private oneNumber(password: string): boolean {
	// 	return /[0-9]/.test(password)
	// }

	// private oneSpecialCharacter(password: string): boolean {
	// 	return /[^A-Za-z0-9]/.test(password)
	// }

	// // custom validation
	// private CustomValidation(control: AbstractControl): ValidationErrors | null {
	// 	if(!control.value) return null;
		
	// 	const hasUpperCase = /[A-Z]/.test(control.value);
	// 	const haslowerCase = /[a-z]/.test(control.value);
	// 	const hasNumeric = /[0-9]/.test(control.value);
	// 	const hasSpecial = /[^A-Za-z0-9]/.test(control.value)
	// 	const isMinLength = control.value.length >= 12;

	// 	const errors = {
	// 		hasUpperCase: !hasUpperCase,
	// 		haslowerCase: !haslowerCase,
	// 		hasNumeric: !hasNumeric,
	// 		hasSpecial: !hasSpecial,
	// 		isMinLength: !isMinLength
	// 	}

	// 	// const errorMessages = [];
	// 	// if(!isMinLength) errorMessages.push('12 characters');
	// 	// if(!haslowerCase) errorMessages.push('1 lowecase letter');
	// 	// if(!hasUpperCase) errorMessages.push('1 uppercase letter');
	// 	// if(!hasNumeric) errorMessages.push('1 number');
	// 	// if(!hasSpecial) errorMessages.push('1 symbol');


	// 	const isValid = hasUpperCase && haslowerCase && hasNumeric && hasSpecial && isMinLength;

	// 	// return errorMessages.length ? {password: errorMessages.join(', ')} : null;
	// 	return !isValid ? errors : null; 
	// }

	// public copyPassword(): void {
	// 	navigator.clipboard.writeText(this.form.get('password').value);
	// }

	public passwordFieldHTML = `
<kendo-formfield>
<kendo-label [for]="loginPassword" text="Password"></kendo-label>
<kendo-textbox class="sh-custom-password" formControlName="loginPassword" #loginPassword required (keydown.enter)="$event.preventDefault()">
	<ng-template kendoTextBoxSuffixTemplate>
	<button kendoButton [title]="iconView" [attr.aria-label]="iconView + ' password'"  
		[svgIcon]="toggleIcon" (click)="toggleLoginVisibility()" (keydown.enter)="toggleLoginVisibility()">
		</button>
	</ng-template>
</kendo-textbox>
<kendo-formerror>Enter your password to log in.</kendo-formerror>
</kendo-formfield>`;
}
