// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const dataTableStylesDefaultTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Grid row background',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: 'Grid row background (alternative)',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: 'Grid row background (selected)',
		property: 'background-color',
		value: 'D3F0F7',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: 'FFE6D9',
		isColorTile: true,
	},
];

export const dataTableStylesDarkTheme = [
	{
		name: 'Header background',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Header text',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Border',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: 'Grid row background',
		property: 'background-color',
		value: '15293A',
		isColorTile: true,
	},
	{
		name: 'Grid row background (alternative)',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: 'Grid row background (selected)',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Text (selected)',
		property: 'color',
		value: 'E7E9E9',
		isColorTile: true,
	},
	{
		name: 'Background: hover',
		property: 'background-color',
		value: '253746',
		isColorTile: true,
	},
];

export const dataTableStylesTypography = [
	{
		name: 'Column header text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: 'Grid cell text',
		property: 'font-family',
		value: '"Open Sans", Arial, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
];

export const dataTableStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100%',
		isColorTile: false,
	},
	{
		name: 'Table header',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Grid body',
		property: 'border-left-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-right-width',
		value: '1px',
		isColorTile: false,
	},
	{
		name: 'Grid row',
		property: 'min-height',
		value: '42px',
		isColorTile: false,
	},
	{
		name: 'Grid cell',
		property: 'padding-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '24px',
		isColorTile: false,
	},
];
