<main class="page-main">
	<app-page-sub-nav [data]="pageSubNavData" [currVisible]="currVisible"></app-page-sub-nav>
	<section class="page-section" id="overview">
		<h2 id="sectionTitleOverview" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('overview')">
			Overview <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<app-external-resource [componentName]="'Data Table'" [otherNames]="true" [includeDescription]="true"></app-external-resource>
	</section>
	<section class="page-section" id="example">
		<h2 id="sectionTitleExample" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('example')">
			Live example <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<form class="example-container k-body">
			<div class="page-live-header">
				<select class="page-live-example-select" (change)="changeCurrLive($event)" title="Change example">
					<option value="default">Data table</option>
					<option value="empty">Empty data table</option>
				</select>
				<app-theme-switcher></app-theme-switcher>
			</div>
			<div class="page-live-example-content">
				<kendo-grid
					[class.page-live-show]="currLive === 'default'"
					[kendoGridBinding]="dataTableData"
					[height]="600"
					style="max-width: 1200px"
					class="sh-data-table"
					[pageSize]="10"
					[pageable]="{ type: 'input', pageSizes: [25, 50, 100] }"
					[sortable]="true"
					[reorderable]="true"
					[resizable]="true">
					<kendo-grid-column field="Name" title="Name" [width]="160"></kendo-grid-column>
					<kendo-grid-column field="Count" [width]="70" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column field="ConnectionPoint" title="Connection point" [width]="200">
					</kendo-grid-column>
					<kendo-grid-column field="Connectivity" title="Connectivity" [width]="160">
						<ng-template kendoGridCellTemplate let-dataItem>
							<kendo-svg-icon [icon]="iconSet(dataItem)" [themeColor]="iconSet2(dataItem)"></kendo-svg-icon>
							{{ dataItem.Connectivity }}
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="Severity" title="Severity" [width]="200">
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="sh-severity-dots sh-severity-stacked" [ngClass]="severitySet(dataItem)">
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<span class="sh-severity-label">
									{{ dataItem.Severity.replace('None', 'No') }} severity
								</span>
							</div>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="LastActivity" title="Last activity" [width]="200" format="dd MMM yyy&nbsp;&nbsp;hh:mm a"></kendo-grid-column>
				</kendo-grid>

				<kendo-grid
					[class.page-live-show]="currLive === 'empty'"
					[kendoGridBinding]="[]"
					[height]="600"
					style="max-width: 1200px"
					class="sh-data-table"
					[pageSize]="10"
					[pageable]="{ type: 'input', pageSizes: [25, 50, 100] }"
					[sortable]="true"
					[reorderable]="true"
					[resizable]="true">
					<kendo-grid-column field="Name" title="Name" [width]="160"></kendo-grid-column>
					<kendo-grid-column field="Count" [width]="70" class="sh-grid-cell-right-align" headerClass="sh-grid-header-right-align"></kendo-grid-column>
					<kendo-grid-column field="ConnectionPoint" title="Connection point" [width]="200">
					</kendo-grid-column>
					<kendo-grid-column field="Connectivity" title="Connectivity" [width]="160">
						<ng-template kendoGridCellTemplate let-dataItem>
							<kendo-svg-icon [icon]="iconSet(dataItem)" [themeColor]="iconSet2(dataItem)"></kendo-svg-icon>
							{{ dataItem.Connectivity }}
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="Severity" title="Severity" [width]="200">
						<ng-template kendoGridCellTemplate let-dataItem>
							<div class="sh-severity-dots sh-severity-stacked" [ngClass]="severitySet(dataItem)">
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<div class="dot" aria-hidden="true"></div>
								<span class="sh-severity-label">
									{{ dataItem.Severity }} severity
								</span>
							</div>
						</ng-template>
					</kendo-grid-column>
					<kendo-grid-column field="LastActivity" title="Last activity" [width]="200" format="dd MMM yyy&nbsp;&nbsp;hh:mm a"></kendo-grid-column>
				</kendo-grid>
			</div>
		</form>
	</section>
	<section class="page-section" id="dev">
		<h2 id="sectionTitleDev" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('dev')">
			Developer resources <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="dev-code">
			<h3 id="subSectionTitleDevCode" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('dev-code')">
				Code examples <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<pre prism class="dark page-pre-code" [hidden]="currLive !== 'default'">
				{{ dataTableHTML }}
			</pre>
			<pre prism class="dark page-pre-code" [hidden]="currLive !== 'empty'">
				{{ dataTableEmptyHTML }}
			</pre>
		</section>
	</section>
	<section class="page-section" id="styles">
		<h2 id="sectionTitleStyles" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('styles')">
			Styles <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-section-list">
			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (light theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefault" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDefaultPagination" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">
						Color (dark theme)
					</summary>

					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDark" [data]="item"></app-style-row>
					</ul>

					<span class="page-style-list-title">Pagination</span>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Color / Token</span>
						</li>
						<app-style-row *ngFor="let item of stylesDarkPagination" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Typography</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesTypography" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>

			<li class="page-section-list-item">
				<details class="page-style-details">
					<summary class="page-style-summary">Structure</summary>
					<ul class="page-style-list">
						<li class="page-style-list-item page-style-list-item-header">
							<span>Element</span><span>Property</span><span>Value</span>
						</li>
						<app-style-row *ngFor="let item of stylesStructure" [data]="item"></app-style-row>
					</ul>
				</details>
			</li>
		</ul>
	</section>
	<section class="page-section" id="behaviors">
		<h2 id="sectionTitleBehaviors" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('behaviors')">
			Behaviors <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="behaviors-empty">
			<h3 id="subSectionTitleBehaviorsEmpty" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-empty')">
				Empty state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A table is in an empty state when there is no data to display, an
					error occurs when loading the data, or a filter has no matching rows.
				</li>
				<li class="page-section-list-item">
					There’s a difference between a table that’s still loading data and a
					table that has no data to display.
				</li>
				<li class="page-section-list-item">
					A message in the middle of the table notifies the user why
					the table is empty. This can include a button to fix the problem,
					an icon to indicate there was an error, or just a brief message.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-loading">
			<h3 id="subSectionTitleBehaviorsLoading" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-loading')">
				Loading state <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					When data is loading a repeating progress indicator line appears
					directly beneath the table header. This progress indicator is the
					full width of the table.
				</li>
				<li class="page-section-list-item">
					Until the loading is done, all functionality is disabled. This
					includes filters, table headers, and pagination. Once loading
					finishes the functionality is restored and the progress indicator
					disappears.
				</li>
				<li class="page-section-list-item">
					Override Kendo’s default loading template with a progress indicator.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-columns">
			<h3 id="subSectionTitleBehaviorsColumns" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-columns')">
				Column resizing and reordering <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					If drag and drop is enabled, selecting a column allows users to drag it
					to a new location. When the user drops the column header in the new position,
					this immediately changes the ordering of all data in the table.
				</li>
				<li class="page-section-list-item">
					If resizing is enabled, users can resize data table columns by dragging
					the edges of the header cells.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-filter">
			<h3 id="subSectionTitleBehaviorsFilter" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-filter')">
				Filter <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					A table filter is included by default. This might be removed
					in favor of using the larger filter pattern. On the user side, the filter can
					directly work on the data in the table, but on the server-side the developer
					needs to reach back out to the server to perform filtering.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-pagination">
			<h3 id="subSectionTitleBehaviorsPagination" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-pagination')">
				Pagination <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Every table with at least one row of data has a pagination control at the
					bottom. The pagination is disabled until the table has finished loading.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="behaviors-sorting">
			<h3 id="subSectionTitleBehaviorsSorting" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('behaviors-sorting')">
				Sorting <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Selecting a sortable column header sorts the column, or reverses the order
					if it’s already sorted. Columns sort ascending on first click, and a second
					click switches it to descending. A third click removes the sorting completely
					on that column.
				</li>
				<li class="page-section-list-item">
					The column titles are selectable when hovered over with the pointer cursor.
					If a sort is applied, a small up or down arrow appears next to the column title.
					If the arrow is up, the order is ascending. If the arrow is down, the order
					is descending.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="usage">
		<h2 id="sectionTitleUsage" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('usage')">
			Usage guidelines <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<section class="page-sub-section" id="usage-content">
			<h3 id="subSectionTitleUsagContent" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-content')">
				Content <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Column titles</span>
					Aim for a few words when choosing column titles. Ideally, column titles
					should be no more than 2 lines and should not truncate.
				</li>
				<li class="page-section-list-item">
					Column titles should be bottom-aligned and sentence case. Include units of measurement, if applicable.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Cell values</span>
					Cell values should be concise. Longer values can truncate or wrap by no more than 2
					lines (unless unavoidable). No cell should be empty. Cells without a value should show
					two hyphens --
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Components in cells</span>
					Try not to mix and match components in a single cell.
				</li>
				<li class="page-section-list-item">
					Data tables can only use non-interactive components in cells. Including icons, severity, and tags.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Icons</span>
					Always pair icons with text in data tables. Icons should be used to enhance meaning, not for
					decoration. There is a default icon color depending on light theme or dark theme. Icons in data
					tables that don’t use color to indicate meaning, like product logos, can be a range of colors.
				</li>
				<li class="page-section-list-item">
					<span class="page-section-list-item-sub-title">Default sort order</span>
					Information should be organized in a logical, meaningful way, such as chronologically or alphabetically.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-placement">
			<h3 id="subSectionTitleUsagePlacement" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-placement')">
				Placement <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Data tables can be inside accordions and panels.
				</li>
				<li class="page-section-list-item">
					Data tables extend to the edge of their parent container, ignoring
					padding and margins. There should be a gap between the bottom of the
					grid and the bottom of the browser or page.
				</li>
			</ul>
		</section>
		<section class="page-sub-section" id="usage-tables-vs-grids">
			<h3 id="subSectionTitleUsageTablesVsGrids" class="page-sub-section-title" title="Copy Link to Clipboard"
				(click)="copyToClipboard('usage-tables-vs-grids')">
				Data tables versus data grids <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
			</h3>
			<ul class="page-section-list">
				<li class="page-section-list-item">
					Use a data table instead of a data grid when space is limited, or
					for read-only datasets that don’t rely on row actions or selections.
				</li>
			</ul>
		</section>
	</section>
	<section class="page-section" id="related">
		<h2 id="sectionTitleRelated" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('related')">
			Related <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<span class="page-related-list-title">Referenced in this section:</span>
		<ul class="page-section-list page-related-list">
			<li class="page-related-list-item">
				<a href="components/icon">Icon component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/severity">Severity component</a>
			</li>
			<li class="page-related-list-item">
				<a href="components/tag">Tag component</a>
			</li>
			<li class="page-related-list-item">
				<a href="patterns/filter">Filter pattern</a>
			</li>
		</ul>
	</section>
	<section class="page-section" id="history">
		<h2 id="sectionTitleHistory" class="page-section-title" title="Copy Link to Clipboard"
			(click)="copyToClipboard('history')">
			Revision history <kendo-svg-icon [icon]="linkIcon"></kendo-svg-icon>
		</h2>
		<ul class="page-history-list">
			<li class="page-history-list-item" *ngFor="
						let subItem of changelogService.getChangelogBySection(
							'Data Table Component'
						)
					">
				<span class="page-history-list-item-title">{{
					changelogService.formatDate(subItem.changeDate)
					}}</span>
				<a class="page-history-list-item-link" target="_blank" *ngIf="subItem.workId != ''" [attr.href]="
							'https://beyondtrust.atlassian.net/jira/software/c/projects/SDS/issues/' +
							subItem.workId
						">[{{ subItem.workId }}]</a>{{ subItem.changeName }}
			</li>
		</ul>
	</section>
</main>
