import { Component, OnDestroy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router';
import { ClipboardService } from 'src/app/services/clipboard.service';
import { WindowScrollService } from 'src/app/services/window-scroll.service';
import { ChangelogService } from 'src/app/services/changelog.service';

// Data Sources
import {
	buttonStylesDefaultThemeIcon,
	buttonStylesDarkThemeIcon
} from '../button/button-styles';
import {
	sliderStylesDefaultTheme,
	sliderStylesDarkTheme,
	sliderStylesTypography,
	sliderStylesStructure
} from './slider-styles';

@Component({
	selector: 'app-slider',
	templateUrl: './slider.component.html',
	styleUrls: ['./slider.component.less']
})
export class SliderComponent implements OnDestroy {
	public currLive = 'horizontal';
	public currTheme = 'default';
	public currVisible = 'overview';

	public horizontalSliderValue: number = 0;
	public verticalSliderValue: number = 0;

	// Imported Style Variables
	public stylesDefault = sliderStylesDefaultTheme;
	public stylesDark = sliderStylesDarkTheme;
	public stylesTypography = sliderStylesTypography;
	public stylesStructure = sliderStylesStructure;

	public stylesIconButtonDefault = buttonStylesDefaultThemeIcon;
	public stylesIconButtonDark = buttonStylesDarkThemeIcon;

	public pageSubNavData = [
		{
			name: 'Overview',
			value: 'overview',
			empty: false,
			children: []
		},
		{
			name: 'Live example',
			value: 'example',
			empty: false,
			children: []
		},
		{
			name: 'Developer resources',
			value: 'dev',
			empty: false,
			children: [
				{
					name: 'Getting started',
					value: 'started'
				},
				{
					name: 'Code examples',
					value: 'code'
				},
				{
					name: 'API',
					value: 'api'
				}
			]
		},
		{
			name: 'Styles',
			value: 'styles',
			empty: false,
			children: []
		},
		{
			name: 'Behaviors',
			value: 'behaviors',
			empty: false,
			children: [
				{
					name: 'Select',
					value: 'select'
				},
				{
					name: 'Focus',
					value: 'focus'
				},
				{
					name: 'Hover',
					value: 'hover'
				},
				{
					name: 'Disable',
					value: 'disable'
				}
			]
		},
		{
			name: 'Variations',
			value: 'variations',
			empty: false,
			children: [
				{
					name: 'Horizontal',
					value: 'horizontal'
				},
				{
					name: 'Vertical',
					value: 'vertical'
				}
			]
		},
		{
			name: 'Usage guidelines',
			value: 'usage',
			empty: false,
			children: [
				{
					name: 'General',
					value: 'general'
				},
				{
					name: 'Content',
					value: 'content'
				},
				{
					name: 'Slider versus range slider',
					value: 'slider-vs-range-slider'
				},
				{
					name: 'Slider versus number field',
					value: 'slider-vs-number-field'
				}
			]
		},
		{
			name: 'Related',
			value: 'related',
			empty: false,
			children: []
		},
		{
			name: 'Revision history',
			value: 'history',
			empty: false,
			children: []
		}
	];

	constructor(
		private viewportScroller: ViewportScroller,
		private clipboardService: ClipboardService,
		public changelogService: ChangelogService,
		scrollService: WindowScrollService
	) {
		scrollService.sectionChange.subscribe((currentSection: string) => {
			this.onVisible(currentSection);
		});
	}

	ngOnDestroy() {
		document.documentElement.setAttribute('data-theme', 'light');
	}

	public onAnchorClick(elementId: string): void {
		this.viewportScroller.scrollToAnchor(elementId);
	}

	public changeCurrLive(event): void {
		this.currLive = event.target.value;
	}

	public changeCurrTheme(event): void {
		this.currTheme = event.target.value;
	}

	public copyToClipboard(newValue): void {
		this.clipboardService.createClipboard({
			value: newValue,
			type: 'url'
		});
	}

	public onVisible(section: string): void {
		this.currVisible = section;
	}

	public horizontalSliderHTML = `
<div
	class="sh-slider-container"
>
	<kendo-label
		text="Maximum Accounts (Required): {{ horizontalSliderValue }}"
		[for]="horizontalSlider"
	>
	</kendo-label>
	<kendo-slider
		#horizontalSlider
		name="horizontalSlider"
		[largeStep]="1"
		[min]="0"
		[max]="10"
		[smallStep]="1"
		[(ngModel)]="horizontalSliderValue"
	></kendo-slider>
	<kendo-formhint>Select a number between 0 and 10.</kendo-formhint>
</div>`;

	public verticalSliderHTML = `
<div
	class="sh-slider-container"
>
	<kendo-label
		text="Maximum Accounts (Required): {{ verticalSliderValue }}"
		[for]="verticalSlider"
	>
	</kendo-label>
	<kendo-slider
		#verticalSlider
		name="verticalSlider"
		[largeStep]="1"
		[min]="0"
		[max]="10"
		[smallStep]="1"
		[vertical]=true
		[(ngModel)]="verticalSliderValue"
	></kendo-slider>
	<kendo-formhint>Select a number between 0 and 10.</kendo-formhint>
</div>`;

	public disabledSliderHTML = `
<div
	class="sh-slider-container"
>
	<kendo-label
		text="Maximum Accounts (Required): 0"
		[for]="disabledSlider"
	>
	</kendo-label>
	<kendo-slider
		#disabledSlider
		name="disabledSlider"
		[disabled]="true"
		[largeStep]="1"
		[min]="0"
		[max]="10"
		[smallStep]="1"
	></kendo-slider>
	<kendo-formhint>Select a number between 0 and 10.</kendo-formhint>
</div>`;
}
