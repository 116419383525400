// Variable naming convention
// [component] + Styles + [Section] + [Variation]
// Styles split into sections of 'DefaultTheme', 'DarkTheme', 'Structure', and 'Typography'
// Examples: accordionStylesStructureWide or buttonStylesDarkThemePrimary

export const paginationStylesDefaultTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: 'F8F9F9',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'D0D3D4',
		isColorTile: true,
	},
];

export const paginationTextFieldStylesDefaultTheme = [
	{
		name: 'Input box text',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: 'FFFFFF',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: '253746',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: 'C8CDD1',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'C14100',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '007D9A',
		isColorTile: true,
	},
];

export const paginationStylesDarkTheme = [
	{
		name: 'Text',
		property: 'color',
		value: '007D9A',
		isColorTile: true,
	},
	{
		name: 'Container',
		property: 'background-color',
		value: '425563',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '425563',
		isColorTile: true,
	},
];

export const paginationTextFieldStylesDarkTheme = [
	{
		name: 'Input box text',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Input box',
		property: 'background-color',
		value: '081826',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: 'A8AFB5',
		isColorTile: true,
	},
	{
		name: 'Icon (left of input box text)',
		property: 'color',
		value: 'D0D3D4',
		isColorTile: true,
	},
	{
		name: 'Icon background (circular): hover',
		property: 'background-color',
		value: '125667',
		isColorTile: true,
	},
	{
		name: 'Container: focus / active',
		property: 'outline-color',
		value: 'FF8040',
		isColorTile: true,
	},
	{
		name: '',
		property: 'border-color',
		value: '4EC3E0',
		isColorTile: true,
	},
];

export const paginationStylesTypography = [
	{
		name: 'Text and labels',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'normal / 400',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const paginationTextFieldStylesTypography = [
	{
		name: 'Text (label)',
		property: 'font-family',
		value: '"Open Sans", Arial, Helvetica, sans-serif',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'font-weight',
		value: 'semibold / 600',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
	{
		name: 'Text (input)',
		property: 'font-size',
		value: '14px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'line-height',
		value: '18px',
		isColorTile: false,
	},
];

export const paginationStylesStructure = [
	{
		name: 'Container',
		property: 'width',
		value: '100% of parent control',
		isColorTile: false,
	},
	{
		name: '',
		property: 'height',
		value: '52px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false,
	},
];

export const paginationDropDownStylesStructure = [
	{
		name: 'Container',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '80px',
		isColorTile: false,
	},
	{
		name: 'Container (area before first item row)',
		property: 'padding-top',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Container (area after last item row)',
		property: 'padding-bottom',
		value: '8px',
		isColorTile: false,
	},
	{
		name: 'Item Row',
		property: 'height',
		value: '48px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-left',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-right',
		value: '16px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-up',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'padding-down',
		value: '12px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '48px',
		isColorTile: false,
	},
];

export const paginationTextFieldStylesStructure = [
	{
		name: 'Container',
		property: 'margin-left',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'margin-right',
		value: '8px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-width',
		value: '68px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'min-height',
		value: '30px',
		isColorTile: false
	},
	{
		name: '',
		property: 'border-width',
		value: '1px',
		isColorTile: false
	},
	{
		name: 'Outline: focus',
		property: 'offset',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'weight',
		value: '1px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'dashes',
		value: '2px',
		isColorTile: false,
	},
	{
		name: '',
		property: 'gap',
		value: '2px',
		isColorTile: false,
	},
];
